<template>
 <v-container fluid id="steps" class="pa-0">
      <v-row class="primary pt-4 mx-0">
        <v-col
          cols="12"
          sm="12"
          md="8"
          offset-md="2"
          class="d-flex justify-center align-center flex-column py-12"
        >
          <h2 class="white--text text-center">
            10 pasos para implementar nuestra tecnología en tu empresa
          </h2>
          <v-btn outlined color="white" rounded large class="mt-5" target="_blank" href="/10 pasos para implemetar tecnologia.pdf">
          Descargar guía
        </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>
<style scoped>
.icon-size {
  height: 40px;
}
</style>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiSeal,
  mdiRadar,
  mdiThumbUpOutline,
  mdiHandHeartOutline,
} from "@mdi/js";

export default {
  name: "Steps",
  components: {
    SvgIcon,
  },
  data: () => ({
    sealPath: mdiSeal,
    radarPath: mdiRadar,
    thumbPath: mdiThumbUpOutline,
    handPath: mdiHandHeartOutline,
  }),
  computed: {
  },
};
</script>
